<cui-logged-layout [freeContentStyle]="false" [noTitleBar]="false">
    <cui-layout-header>
        <base-navbar></base-navbar>
    </cui-layout-header>
    <cui-layout-footer>
        <base-footer></base-footer>
        <popup-cookies></popup-cookies>
        <typeform-widgets-view></typeform-widgets-view>
    </cui-layout-footer>
</cui-logged-layout>
