<ll-over-title>
    <arte-home-showcasebox-barra [pagina]="pagina"></arte-home-showcasebox-barra>
</ll-over-title>

<div class="row">
    <div class="col-md-4 col-sm-6">
        <usuario-showcasebox></usuario-showcasebox>
        <br />
        <notificaciones-showcasebox></notificaciones-showcasebox>
        <br />
        <noticias-alumnos-showcasebox></noticias-alumnos-showcasebox>
    </div>
    <div class="col-md-4 col-sm-6">
        <action-menu-showcase [menuCustom]="menu"></action-menu-showcase>
    </div>
    <div class="col-md-4 col-sm-6">
        <arte-home-showcasebox [pagina]="pagina"></arte-home-showcasebox>
        <br />
    </div>
</div>
<arte-zona-modal [pagina]="pagina"></arte-zona-modal>
