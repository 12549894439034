import { BaseModel, Validations, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"
import { UsuarioChile } from "./usuario_chile.model"
import { Lugar } from "./lugares.model"
import { Establecimiento } from "./establecimientos.model"
import { UsuarioRegistroBase } from "./usuarios_registro_base.model"
import { PreferenciaCarreraSede } from "@puntaje/carreras/api-services"

export class UsuarioRegistroUdla extends UsuarioRegistroBase {
    public pasaporte: string
    public tipo_id: number

    public toUsuario() {
        const user = super.toUsuario()

        user.pasaporte = this.pasaporte

        return user
    }
}
