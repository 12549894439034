import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { AuthService, SessionService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Usuario, Usuarios } from "@puntaje/puntaje/api-services"

@Component({
    templateUrl: "home.component.html",
    styleUrls: ["home.component.scss"]
})
export class HomeComponent implements OnInit {
    config: typeof config
    @Input() pagina: string = "home"
    isPro = false
    dias = false
    dias_faltantes = 0
    frase: string = ""
    mailSinConfirmar: boolean = false
    //@ViewChild('loadingLayout') loadingLayout: LoadingLayoutComponent;
    asignaturasBoxInsteadPlanClase = false
    menu: any[] = []

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        private authService: AuthService,
        protected sessionService: SessionService,
        protected usuariosService: Usuarios,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        this.config = config
    }

    ngOnInit() {
        this.isPro = this.sessionService.isPro()
        this.setMailSinConfirmar()
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true, noTitleBar: true })
    }

    setMenuItems() {
        this.menu = [
            {
                title: "Ejercita",
                icon: "prueba",
                link: "/pruebas/generar_prueba",
                text: "En esta sección podrás crear ensayos personalizados según tus preferencias permitiéndote reforzar los contenidos de tu elección"
            },
            {
                title: "Practica",
                icon: "resultados",
                link: "/evaluaciones/recomendadas",
                text: "Refuerza tus habilidades practicando con los ensayos recomendados, los cuales abarcan todos los contenidos evaluados de la prueba PAES"
            },
            {
                title: "Planes de Clase",
                icon: "list-ol",
                link: "/plan_clases",
                text: "Realiza un plan de clases a través de una estructura predefinida. Revivirás todos los contenidos necesarios para la PAES"
            },
            {
                title: "Carreras",
                icon: "graduation-cap",
                link: "/mis_carreras",
                text: "Explora la información acerca de las carreras que ofrecemos en nuestra universidad, analizando detalladamente las mallas curriculares, sedes y otros"
            }
        ]
    }

    setMailSinConfirmar() {
        const confirmed_at = this.sessionService.getConfirmedAt()
        if (!confirmed_at) {
            this.mailSinConfirmar = true
        }
    }
}
