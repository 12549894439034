import { Component, OnInit, Input, HostBinding } from "@angular/core"
import { Router } from "@angular/router"
import { AppConfig, AuthService } from "@puntaje/shared/core"

declare const config: AppConfig

@Component({
    selector: "action-menu-showcase",
    templateUrl: "./action-menu-showcase.component.html",
    styleUrls: ["./action-menu-showcase.component.scss"]
})
export class ActionMenuShowcaseComponent implements OnInit {
    @Input() menuCustom: any[]
    menu: any[] = [
        {
            title: "Ejercita",
            icon: "prueba",
            link: "/pruebas/generar_prueba",
            text: "En esta sección podrás crear ensayos personalizados según tus preferencias permitiéndote reforzar los contenidos de tu elección"
        },
        {
            title: "Practica",
            icon: "resultados",
            link: "/evaluaciones/recomendadas",
            text: "Refuerza tus habilidades practicando con los ensayos recomendados, los cuales abarcan todos los contenidos evaluados de la prueba PAES"
        },
        {
            title: "Planes de Clase",
            icon: "list-ol",
            link: "/plan_clases",
            text: "En esta sección podrás crear ensayos personalizados según tus preferencias permitiéndote reforzar los contenidos de tu elección"
        },
        {
            title: "Carreras",
            icon: "graduation-cap",
            link: "/mis_carreras",
            text: "Explora la información acerca de las carreras que ofrecemos en nuestra universidad, analizando detalladamente las mallas curriculares, sedes y otros"
        }
    ]

    @HostBinding("class") innerClass = "showcasebox_style_1_primary"

    constructor(protected router: Router, protected authService: AuthService) {}

    ngOnInit() {
        this.menu = this.menuCustom ? this.menuCustom : this.menu
    }
}
