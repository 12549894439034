import { SharedLayoutComponent } from "@puntaje/platforms/udla"
import { SimpleSharedLayoutComponent } from "@puntaje/platforms/udla"
import {
    UsuariosEditComponent,
    UsuarioPerfilComponent,
    TutorConfirmarComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CompletarRegistroComponent,
    RegisterConfirmationComponent,
    IncompleteRegisterComponent,
    RegisterComponent,
    CompletarRegistroSocialComponent,
    OpcionesRegistroNacimientoComponent,
    RegistroComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard, UtmParamsGuard } from "@puntaje/puntaje/services"

import { RegisterUdlaComponent } from "@puntaje/puntaje/new-modules/usuarios"

export const routes: Routes = [
    //{ path: 'usuarios', component: UsuariosComponent, canActivate: [TokenGuard, AuthGuard] },
    /*{ path: 'usuarios/login', component: LoginComponent },*/
    {
        path: "usuarios/register",
        component: SharedLayoutComponent,
        canActivate: [UtmParamsGuard],
        children: [{ path: "", component: RegisterComponent, canActivate: [UtmParamsGuard] }]
    },
    {
        path: "usuarios/registro",
        component: SharedLayoutComponent,
        canActivate: [UtmParamsGuard],
        children: [
            {
                path: "",
                component: RegistroComponent,
                data: { component: RegisterUdlaComponent },
                canActivate: [UtmParamsGuard]
            }
        ]
    },
    {
        path: "usuarios/opciones",
        component: SharedLayoutComponent,
        canActivate: [UtmParamsGuard],
        children: [{ path: "", component: OpcionesRegistroNacimientoComponent, canActivate: [UtmParamsGuard] }]
    },
    {
        path: "usuarios/:id/completar_registro",
        component: SharedLayoutComponent,
        canActivate: [UtmParamsGuard],
        children: [{ path: "", component: CompletarRegistroComponent, canActivate: [UtmParamsGuard] }]
    },
    {
        path: "usuarios/:id/completar_registro_social",
        component: SharedLayoutComponent,
        canActivate: [UtmParamsGuard],
        children: [{ path: "", component: CompletarRegistroSocialComponent, canActivate: [UtmParamsGuard] }]
    },
    {
        path: "usuarios/confirmar_email/:token",
        component: RegisterConfirmationComponent,
        canActivate: [UtmParamsGuard]
    },
    {
        path: "usuarios/edit",
        component: LoggedLayoutComponent,
        canActivate: [UtmParamsGuard],
        children: [{ path: "", component: UsuariosEditComponent, canActivate: [UtmParamsGuard, TokenGuard, AuthGuard] }]
    },
    {
        path: "usuarios/editar",
        component: LoggedLayoutComponent,
        canActivate: [UtmParamsGuard],
        children: [{ path: "", component: UsuariosEditComponent, canActivate: [UtmParamsGuard, TokenGuard, AuthGuard] }]
    },
    {
        path: "usuarios/:id",
        component: LoggedLayoutComponent,
        canActivate: [UtmParamsGuard],
        children: [
            {
                path: "",
                component: UsuarioPerfilComponent,
                canActivate: [UtmParamsGuard, TokenGuard, AuthGuard],
                data: { tab: "" }
            }
        ]
    },
    {
        path: "usuarios/passwords/reset/:reset_password_token",
        component: SimpleSharedLayoutComponent,
        canActivate: [UtmParamsGuard],
        children: [{ path: "", component: ResetPasswordComponent, canActivate: [UtmParamsGuard] }]
    },
    {
        path: "usuarios/passwords/forgot",
        component: SimpleSharedLayoutComponent,
        canActivate: [UtmParamsGuard],
        children: [{ path: "", component: ForgotPasswordComponent, canActivate: [UtmParamsGuard] }]
    },
    {
        path: "tutor/confirmar/:token",
        component: SimpleSharedLayoutComponent,
        canActivate: [UtmParamsGuard],
        children: [{ path: "", component: TutorConfirmarComponent, canActivate: [UtmParamsGuard] }]
    },
    {
        path: "pending_info",
        component: SharedLayoutComponent,
        canActivate: [UtmParamsGuard],
        children: [{ path: "", component: IncompleteRegisterComponent, canActivate: [UtmParamsGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
