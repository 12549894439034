<div class="showcasebox">
    <div class="showcasebox_title">
        <div class="showcasebox_title_inner">
            <div class="showcasebox_title_icon">
                <div class="icon-container"><ogr-icon name="todos" class="icon"></ogr-icon></div>
            </div>
            <div class="showcasebox_title_text">¿Qué puedes hacer?</div>
        </div>
    </div>
    <div>
        <div class="item-container" *ngFor="let item of menu">
            <div class="item-content">
                <div class="icon-container">
                    <div class="icon-bg">
                        <ogr-icon class="icon" name="{{ item.icon }}"></ogr-icon>
                        <fa class="icon" name="{{ item.icon }}"></fa>
                    </div>
                </div>
                <div class="text-container">
                    <div class="title">
                        {{item.title}}
                    </div>
                    <div class="description">
                        {{ item.text }}
                    </div>
                </div>
                <div class="footer">
                    <div class="link-container">
                        <a [routerLink]="item.link" class="link">
                            Ver <fa name="play" class="arrow"></fa>
                        </a>
                    </div>
                </div>    
            </div>
        </div>
    </div>
</div>