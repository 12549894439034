import { SharedLayoutComponent } from "@puntaje/platforms/udla"
import { PlaceholderPageComponent, TermsAndConditionsComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard, UtmParamsGuard } from "@puntaje/puntaje/services"
import { LandingComponent } from "./landing.component"
import { HomeComponent } from "./home.component"

export const LandingRoutes: Routes = [
    { path: "landing", component: LandingComponent, canActivate: [UtmParamsGuard] },
    {
        path: "home",
        component: LoggedLayoutComponent,
        canActivate: [UtmParamsGuard],
        children: [{ path: "", component: HomeComponent, canActivate: [UtmParamsGuard, TokenGuard, AuthGuard] }]
    },
    { path: "landing/home", redirectTo: "home", pathMatch: "full"},
    {
        path: "condicionesUso",
        component: SharedLayoutComponent,
        canActivate: [UtmParamsGuard],
        children: [{ path: "", component: TermsAndConditionsComponent, canActivate: [UtmParamsGuard] }]
    },
    {
        path: "pronto",
        component: LoggedLayoutComponent,
        canActivate: [UtmParamsGuard],
        children: [{ path: "", component: PlaceholderPageComponent, canActivate: [UtmParamsGuard] }]
    }
]

export const LandingRouting = RouterModule.forChild(LandingRoutes)
