import { NotificacionesComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard, UtmParamsGuard } from "@puntaje/puntaje/services"

export const routes: Routes = [
    {
        path: "notificaciones",
        component: LoggedLayoutComponent,
        canActivate: [UtmParamsGuard],
        children: [
            { path: "", component: NotificacionesComponent, canActivate: [UtmParamsGuard, TokenGuard, AuthGuard] }
        ]
    }
]

export const routing = RouterModule.forChild(routes)
