import { BaseForm, Validations, GroupValidator, DateHelper, AppConfig } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"
import { Usuarios } from "./usuarios.service"
import { UsuarioRegistroBaseChileForm } from "./usuario_registro_base_chile.form"
import { Clasificaciones } from "@puntaje/nebulosa/api-services"

declare const config: AppConfig

export class UsuarioRegistroUdlaForm extends BaseForm {
    public static get globalValidations(): GroupValidator[] {
        return [Validations.equalPasswords(["password", "password_confirmation"])]
    }

    companyName: string = ""

    public static get formParams(): any {
        const companyName = config.plataforma.info.companyName
        const tipoIdOptions = [
            { id: 0, toString: () => "RUT" },
            { id: 1, toString: () => "Pasaporte" }
        ]

        const params: any = {
            nombre: {
                label: "Nombres",
                type: "text",
                visible: true,
                placeholder: "Nombres",
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_paterno: {
                label: "Primer apellido",
                type: "text",
                visible: true,
                placeholder: "Primer apellido",
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_materno: {
                label: "Segundo apellido",
                type: "text",
                visible: true,
                placeholder: "Segundo apellido",
                validations: [Validators.minLength(2)]
            },
            tipo_id: {
                label: "Tipo de documento",
                type: "select",
                visible: true,
                options: tipoIdOptions,
                validations: [Validators.required]
            },
            rut: {
                label: "Número de documento",
                type: "text",
                visible: true,
                placeholder: "11111111-1",
                validations: [Validators.required, Validations.validateRut],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueRut }]
            },
            pasaporte: {
                label: "Número de documento",
                type: "text",
                visible: true,
                placeholder: "",
                validations: [Validators.required, Validations.validatePasaporte],
                customError: {
                    validatePasaporte: `Ingrese un número de documento correcto. Si el error persiste contactar a ${config?.plataforma?.info?.soporte?.mail}`
                }
            },
            fecha_nacimiento: {
                label: "Fecha de nacimiento",
                type: "date",
                visible: true,
                min: "1900-01-01",
                max: DateHelper.today(),
                validations: [Validators.required]
            },
            email: {
                label: "Email",
                type: "text",
                visible: true,
                placeholder: "correo@mail.com",
                validations: [Validators.required, Validations.validateEmail],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueEmail }]
            },
            password: {
                label: "Contraseña",
                type: "password",
                visible: true,
                placeholder: "Contraseña",
                validations: [Validators.required, Validators.minLength(8)]
            },
            password_confirmation: {
                label: "Confirmar contraseña",
                type: "password",
                visible: true,
                placeholder: "Repetir contraseña",
                global: true,
                validations: [Validators.required, Validators.minLength(8)]
            },
            terminos_y_condiciones: {
                innerhtml:
                    "He leído y acepto las <a href='/condicionesUso' target='_blank'>condiciones generales de uso de " +
                    companyName +
                    "</a>",
                type: "checkbox",
                visible: true,
                validations: [Validators.required],
                customError: "Debe aceptar los términos generales de uso de " + companyName
            },
            telefono: {
                label: "Teléfono celular",
                type: "text",
                visible: true,
                placeholder: "981234567",
                validations: [Validators.required, Validations.validateTelefonoChile]
            },
            nivel_id: {
                label: "Curso",
                type: "select",
                visible: true,
                options: {
                    class: Clasificaciones,
                    params: {
                        clasificacion: { id: [13, 9, 10, 11, 12] },
                        clasificacion_tipo: { clasificacion_tipo: "curso" }
                    }
                },
                validations: [Validators.required]
            },
            // lugar: {
            //     label: "Comuna",
            //     type: "autocomplete",
            //     visible: true,
            //     placeholder: "Escribe y selecciona entre las opciones",
            //     // options: { class: Lugares, params: { lugar_tipo: "Comuna" } },
            //     validations: [Validators.required]
            // },
            colegio: {
                label: "Colegio",
                type: "text",
                visible: true,
                validations: [Validators.required]
            }
        }

        const classes = {
            chile: UsuarioRegistroBaseChileForm
        }

        return params
    }
}
