import { Component, OnInit, Injector, EventEmitter, Output, ChangeDetectorRef } from "@angular/core"
import { UntypedFormGroup, Validators } from "@angular/forms"
import {
    Usuario,
    Usuarios,
    Lugar,
    Establecimiento,
    Lugares,
    Establecimientos,
    UsuarioRegistroUdla
} from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Router } from "@angular/router"

import { UsuarioRegistroUdlaForm } from "@puntaje/puntaje/api-services"
import { Clasificacion, Clasificaciones } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "register-udla",
    templateUrl: "./register-udla.component.html",
    styleUrls: ["./register-udla.component.scss"]
})
export class RegisterUdlaComponent implements OnInit {
    usuario: UsuarioRegistroUdla
    params: any = UsuarioRegistroUdlaForm.formParams
    form: UntypedFormGroup
    saved: boolean = false
    pais: string = config.plataforma.pais.toLowerCase()
    identificador: any[string] = config.plataforma.identificadorUsuario[0].toLowerCase()

    enableCarreras = config.registro ? config.registro.enableCarreras : false
    universidades = config.registro ? config.registro.universidades || [""] : [""]

    enableTelefono: boolean
    enableNivel: boolean
    enableColegio: boolean
    mensajeFin: string

    lugar: Lugar
    lugarColegio: Lugar
    filteredLugarColegio: Lugar[]
    filteredEstablecimientos: Establecimiento[]
    egresado: Clasificacion

    @Output() onTitleChange: EventEmitter<string> = new EventEmitter<string>()
    soporteMail: string = config.plataforma.info.soporte.mail

    constructor(
        protected router: Router,
        protected injector: Injector,
        protected usuariosService: Usuarios,
        protected lugaresService: Lugares,
        protected establecimientosService: Establecimientos,
        protected clasificacionesService: Clasificaciones,
        public cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.mensajeFin = config.registro.mensajeRegistro
            ? config.registro.mensajeRegistro
            : `Hemos enviado un e-mail a tu correo electrónico con instrucciones para validar tu cuenta. Si no te llega en 5
minutos más, por favor revisa tu carpeta de spam.`
        this.enableTelefono = (config.registro && config.registro.enableTelefono) || false
        this.enableNivel = (config.registro && config.registro.enableNivel) || false
        this.enableColegio = (config.registro && config.registro.enableColegio) || false

        this.usuario = new UsuarioRegistroUdla()
        this.usuario["usuario_" + this.pais] = new Usuario["usuario_" + this.pais]()
        this.form = UsuarioRegistroUdlaForm.getForm(this.usuario, null, this.injector)

        this.clasificacionesService
            .where({
                clasificacion_tipo: { clasificacion_tipo: "curso" },
                clasificacion: { clasificacion: "Egresado" }
            })
            .then((clasificaciones: Clasificacion[]) => {
                this.egresado = clasificaciones[0]
            })
    }

    nivelChangeFn(clasificacionId) {
        if (parseInt(clasificacionId) == this.egresado.id) {
            // this.form.controls.lugar.setValidators(null)
            this.form.controls.colegio.setValidators(null)
        } else {
            // this.form.controls.lugar.setValidators([Validators.required])
            this.form.controls.colegio.setValidators([Validators.required])
        }
        this.form.updateValueAndValidity()
        this.cdr.detectChanges()
    }

    disabledId(tipoId) {
        switch (tipoId) {
            case "0":
                this.form.controls.rut.enable()
                this.form.controls.pasaporte.disable()
                this.usuario["pasaporte"] = undefined
                break

            case "1":
                this.form.controls.pasaporte.enable()
                this.form.controls.rut.disable()
                this.usuario["usuario_" + this.pais][this.identificador] = undefined
                break
        }
    }

    save() {
        UsuarioRegistroUdlaForm.markFormControlsAsTouched(this.form)
        UsuarioRegistroUdlaForm.markFormControlsAsDirty(this.form)
        if (this.form.valid) {
            const user = this.usuario.toUsuario()
            const utmParamsFromStorage = JSON.parse(localStorage.getItem("utmParams"))
            if (utmParamsFromStorage) {
                user["utm_params"] = utmParamsFromStorage
            }
            if (user.establecimiento_id && user.lugar_id) {
                this.usuariosService.save(user).then(response => {
                    this.onTitleChange.emit("¡Ya casi estás listo!")
                    this.saved = true
                    setTimeout(() => {
                        this.router.navigate(["landing"])
                    }, 5000)
                })
            }
        }
    }

    back() {
        this.router.navigate([""])
    }

    updatePreferencias(preferencias) {
        this.usuario.preferenciaCarreraSedes = preferencias
    }

    updateLugar(lugar: any) {
        if (lugar instanceof Lugar) {
            this.usuario.lugar = lugar
            this.lugar = lugar
        }
    }

    updateLugarColegio(lugar: any) {
        if (lugar instanceof Lugar) {
            this.lugarColegio = lugar
            this.updateLugar(lugar)
        }
    }

    getLugarColegio(lugarFilter: string) {
        this.lugaresService
            .where({ per: 100, raw: 1, filter: lugarFilter, lugar_tipo: ["Comuna"] })
            .then((lugares: Lugar[]) => {
                this.filteredLugarColegio = lugares
            })
    }

    updateColegio(establecimiento: Establecimiento) {
        this.usuario.colegio = establecimiento
    }

    getEstablecimientos(establecimientoFilter: string) {
        this.establecimientosService
            .where(this.whereGetEstablecimientos(establecimientoFilter))
            .then((establecimientos: Establecimiento[]) => {
                this.filteredEstablecimientos = establecimientos
            })
    }

    whereGetEstablecimientos(establecimientoFilter: string): any {
        let establecimientoParams = { establecimiento: establecimientoFilter }
        if (this.lugarColegio) {
            establecimientoParams["lugar_id"] = this.lugarColegio.id
        }

        return {
            per: 100,
            raw: 1,
            include: `[establecimiento_${this.pais}]`,
            establecimiento: establecimientoParams,
            lugar_tipo: { lugar_tipo: ["Ciudad", "Región", "Provincia", "Comuna"] }
        }
    }
}
