import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, ParamMap } from "@angular/router"

@Injectable({ providedIn: "root" })
export class UtmParamsGuard implements CanActivate {
    canActivate(route: ActivatedRouteSnapshot) {
        const routeParams: ParamMap = route.queryParamMap
        const utmKeys = routeParams.keys.filter(key => key.includes("utm_"))
        const oldParams = JSON.parse(localStorage.getItem("utmParams")) || {}
        const paramObj = {}
        utmKeys.forEach(key => {
            paramObj[key] = routeParams.get(key)
        })
        if (!utmKeys.includes("utm_source") && !this.hasUtmSource(oldParams)) {
            paramObj["utm_source"] = this.getTrafficSource()
        } else if (!utmKeys.includes("utm_source") && this.hasUtmSource(oldParams)) {
            paramObj["utm_source"] = oldParams["utm_source"]
        }
        paramObj["referrer"] = document.referrer
        Object.assign(oldParams, paramObj)
        localStorage.setItem("utmParams", JSON.stringify(oldParams))
        return true
    }

    hasUtmSource(object) {
        return Object.keys(object).includes("utm_source")
    }
    getTrafficSource() {
        const cookie = document.cookie
            .split(";")
            .map(c => c.trim())
            .find(c => c.startsWith("traffic_source"))
        if (cookie) {
            const [, source] = cookie.split("=")
            return source
        }
        return ""
    }
}
